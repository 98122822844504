define("ember-inflector/lib/utils/make-helper", ["exports", "ember", "@ember/component/helper"], function (_exports, _ember, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = makeHelper;
  function makeHelper(helperFunction) {
    if (_helper.default) {
      return _helper.default.helper(helperFunction);
    }
    if (_ember.default.HTMLBars) {
      return _ember.default.HTMLBars.makeBoundHelper(helperFunction);
    }
    return _ember.default.Handlebars.makeBoundHelper(helperFunction);
  }
});