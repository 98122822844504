define("@ember/-internals/views/lib/compat/fallback-view-registry", ["exports", "@ember/-internals/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.dictionary)(null);

  _exports.default = _default;
});